import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { Constants } from "@core/constants";
import { Course } from "@core/domain/course";
import { CourseDetail } from "@core/domain/course-detail";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { CourseService } from "@core/services/course.service";
import { TranslateService } from "@ngx-translate/core";
import { FlexiblePlanElementModalComponent } from "@shared/modals/flexible-plan-element-modal/flexible-plan-element-modal.component";
import { ScheduleCourseModalComponent } from "@shared/modals/schedule-course-modal/schedule-course-modal.component";
import { LoadingService } from "@shared/services/loading-service";
import { ModalInput, ModalService } from "@shared/services/modal.service";
import { PlanLaterStateService } from "@shared/services/plan-later-state.service";
import { PlanStateService } from "@shared/services/plan-state.service";
import { ToastService } from "@shared/services/toast-service";
import { CourseData, SelectedPeriod } from "@shared/types/course-data";
import { CourseUpdateAction } from "@shared/types/plan-actions";
import { switchMap, tap } from "rxjs";
import { PlanLaterType } from "../types/plan-later-types";

@Injectable({
  providedIn: "root",
})
export class PlanActionService {
  private planLaterStateService = inject(PlanLaterStateService);
  private planStateService = inject(PlanStateService);
  private toastService = inject(ToastService);
  private translate = inject(TranslateService);
  private courseHelper = inject(CourseHelper);
  private modalService = inject(ModalService);

  private courseService = inject(CourseService);
  private loadingService = inject(LoadingService);

  // Signal that can be used to update the course data after a plan action (planNow/planLater/remove)
  courseUpdated: WritableSignal<CourseUpdateAction | undefined> = signal(undefined);

  signalCourseUpdated(course: Course, selectedPeriod?: SelectedPeriod): void {
    this.courseUpdated.set({
      course: course,
      selectedPeriod,
    });
  }

  /**
   * Move a course that is already in the plan grid to a different period.
   */
  private moveCourse(courseData: CourseData, selectedPeriod: SelectedPeriod): void {
    this.planStateService.removeCourseFromPlan(courseData, false);
    this.planCourse(courseData, selectedPeriod);
  }

  /**
   * Add a course to the plan grid, and if needed remove it from Plan Later.
   */
  private planCourse(courseData: CourseData, selectedPeriod: SelectedPeriod): void {
    if (this.planLaterStateService.isCourseInPlanLaterOtherCourses(courseData.code)) {
      // Remove from Plan Later if it was there in "Other courses".
      // Modify the course data because if the planning was done from outside the plan page,
      // the plan later type is not set
      courseData.planLaterType = PlanLaterType.OTHER_COURSES;
      this.planLaterStateService.removeCourse(courseData);
    }
    this.planStateService.addCourseToPeriod(courseData, selectedPeriod);
    courseData.isPlannable = false;
    courseData.plannedText = this.courseHelper.getPlannedText(courseData);

    this.signalCourseUpdated(courseData, selectedPeriod);

    this.toastService.presentToast(
      this.translate.instant("courseSchedule.scheduled", {
        course: `${courseData.code} ${this.courseHelper.getCourseName(courseData)}`,
        year: selectedPeriod.year.yearNr,
        period_oms: selectedPeriod.period.periodDescription,
      }),
    );
  }

  planNow(courseData: CourseData): void {
    if (courseData.ownCourse) {
      // prettier-ignore
      const studyYears = this.planStateService.currentSelectedPlan()?.studyYears
        .map(studyYear => this.courseHelper.toCourseAcademicYear(studyYear));
      this.selectPeriodAndPlan(courseData, { studyYear: studyYears } as CourseDetail);
    } else {
      // prettier-ignore
      this.loadingService.present()
        .pipe(
          switchMap(() => this.courseService.fetchCourse(courseData.code, courseData.planId)),
          tap(() => this.loadingService.dismiss())
        )
        .subscribe(courseDetail => {
          courseDetail && this.selectPeriodAndPlan(courseData, courseDetail);
        });
    }
  }

  async selectPeriodAndPlan(
    courseData: CourseData,
    courseDetail: CourseDetail,
  ): Promise<CourseUpdateAction | undefined> {
    const modal = await this.getScheduleCourseModal(courseData, courseDetail);
    await modal.present();
    const result = await modal.onWillDismiss();

    if (result.role !== Constants.BUTTON_ACTION_OK) {
      return undefined;
    }

    const selectedPeriod = result.data as SelectedPeriod;
    this.updateCourseDataWithSelectedPeriod(courseData, selectedPeriod);
    const updatedCourseData = this.doPlanNow(courseData, selectedPeriod);

    return {
      course: updatedCourseData,
      selectedPeriod: selectedPeriod,
    } as CourseUpdateAction;
  }

  /**
   * Put a course in Plan Later.
   * If the course is already in one of the special sections of plan later, we don't need to do anything.
   * Otherwise, we add it to the "Other courses" section of plan later.
   */
  planLater(courseData: CourseData): CourseData {
    if (!this.planLaterStateService.findCourseInPlanLater(courseData.code)) {
      courseData.isAddedToPlanLater = true;
      courseData.isPartOfPlanLater = true;
      courseData.academicYear = 0;
      courseData.periodNr = 0;
      courseData.periodDescription = "";
      courseData.isInPlanLater = true;
      courseData.id = undefined;

      this.planLaterStateService.addOtherCourse(courseData);
      this.planStateService.markPlanAsModified(true);
    }

    this.toastService.presentToast(
      this.translate.instant("courseMove.movedToPlanLater", {
        course: `${courseData.code} ${this.courseHelper.getCourseName(courseData)}`,
      }),
    );

    return courseData;
  }

  private doPlanNow(courseData: CourseData, selectedPeriod: SelectedPeriod): CourseData {
    courseData.isInPlanLater = false;

    if (courseData.isCustomCourse) {
      courseData.academicYear = 0;
    }
    if (
      courseData.academicYear !== selectedPeriod.year.academicYear ||
      courseData.periodNr !== selectedPeriod.period.periodNr ||
      courseData.isFlexCourse
    ) {
      if (courseData.isPlannedCourse) {
        // it was already in the plan grid, but we are moving it to a different period
        this.moveCourse(courseData, selectedPeriod);
      } else {
        // it was not in the plan grid, so we are adding it
        this.planCourse(courseData, selectedPeriod);
      }
    }

    courseData.isRibbonCourse = this.courseHelper.isRibbonCourse(courseData);

    return courseData;
  }

  removeCourseFromPlan(course: Course): void {
    this.planStateService.removeCourseFromPlan(course);
    this.signalCourseUpdated(course);
  }

  removeCourseFromPlanLater(courseData: CourseData): void {
    this.planLaterStateService.removeCourse(courseData);
  }

  moveCourseFromCourseInfo(courseData: CourseData, selectedPeriod: SelectedPeriod): void {
    this.doPlanNow(courseData, selectedPeriod);
  }

  async planCourseFromCourseInfo(courseData: CourseData, courseDetail: CourseDetail): Promise<void> {
    const modal = await this.getScheduleCourseModal(courseData, courseDetail);
    await modal.present();
    const result = await modal.onWillDismiss();

    const selectedPeriod = result.data as SelectedPeriod;

    switch (result.role) {
      case Constants.BUTTON_ACTION_PLANLATER:
        this.removeCourseFromPlan(courseData);
        this.planLater(courseData);
        courseData.plannedText = this.courseHelper.getPlannedText(courseData);
        break;
      case Constants.BUTTON_ACTION_OK:
        this.updateCourseDataWithSelectedPeriod(courseData, selectedPeriod);
        this.doPlanNow(courseData, selectedPeriod);
        break;
    }
  }

  private async getScheduleCourseModal(
    courseData: CourseData,
    courseDetail: CourseDetail,
  ): Promise<HTMLIonModalElement> {
    const modalInput: ModalInput = {
      component: courseDetail.isFlexCourse ? FlexiblePlanElementModalComponent : ScheduleCourseModalComponent,
      componentProps: {
        courseData: courseData,
        courseDetail: courseDetail,
      },
      cssClass: courseDetail.isFlexCourse ? "flexible-plan-modal" : "schedule-course-modal",
    };

    return this.modalService.createModal(modalInput);
  }

  private updateCourseDataWithSelectedPeriod(courseData: CourseData, selectedPeriod: SelectedPeriod) {
    const hasRibbonPeriods = selectedPeriod.period.ribbonPeriods?.length > 1;

    courseData.ribbonPeriods = hasRibbonPeriods ? selectedPeriod.period.ribbonPeriods : [];
    courseData.hasRibbonPeriods = hasRibbonPeriods;

    courseData.timeslots = selectedPeriod.period.timeslots?.length > 0 ? selectedPeriod.period.timeslots : [];
  }
}
