import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "pa-notfound-page",
  templateUrl: "./not-found-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
export class NotFoundPageComponent implements OnInit {
  status?: number;
  message?: string;
  details?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const data = this.router.getCurrentNavigation()?.extras?.state || this.activatedRoute.snapshot.data;
    // @ts-expect-error (ignore ts error)
    this.status = data?.status;
    // @ts-expect-error (ignore ts error)
    this.message = data?.message ?? ``;
    if (typeof this.message !== "string") {
      this.message = JSON.stringify(this.message);
    }

    // @ts-expect-error (ignore ts error)
    this.details = data?.details ?? `Page '${this.activatedRoute.snapshot.url.toString()}'`;
  }
}
