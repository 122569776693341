import { Injectable } from "@angular/core";
import {
  ApiCursusRequest,
  ApiCursussenRequest,
  ApiExamenonderdeelRequest,
  ApiMinorExamenonderdeelRequest,
  ApiMinorsRequest,
  ApiPeriodeRequest,
  ApiPlanRequest,
  ApiProfileringRequest,
  ApiSpecialisatieRequest,
  ApiSpecialisatieRuimteRequest,
  ApiStudiejaarRequest,
} from "@core/api/model/api-plan-request";
import { Course } from "@core/domain/course";
import { ExamComponent } from "@core/domain/exam-component";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { Period, PlanDetails, StudyYear } from "@core/domain/plan-details";
import { PlanLater, PlanLaterProfile, Specialisation, Specialisations } from "@core/domain/plan-later";
import { MinorProfile, ProfileMinorExamComponent } from "@core/domain/plan-profile";

@Injectable({
  providedIn: "root",
})
export class PlanRequestMappingService {
  public buildApiPlanRequest(plan: PlanDetails, planLater: PlanLater): ApiPlanRequest {
    // prettier-ignore
    return {
      spla_id: plan.id,
      examenprogramma: plan.studyProgram,
      tweede_examenprogramma: plan.secondStudyProgram,
      studiejaren: plan.studyYears.map(year => this.mapStudyYear(year)),
      losse_cursussen: this.mapOtherCourses(planLater.otherCourses),
      profilering: this.mapProfilering(planLater.profile),
      specialisatieruimte: this.mapSpecialisatieRuimte(planLater.specialisation),
    } as ApiPlanRequest;
  }

  private mapStudyYear(year: StudyYear): ApiStudiejaarRequest {
    return {
      studiejaar: year.yearNr,
      periodes: year.periods.map((period) => this.mapPeriod(period)),
    } as ApiStudiejaarRequest;
  }

  private mapPeriod(period: Period): ApiPeriodeRequest {
    // prettier-ignore
    return {
      periode_nr: period.periodNr,
      periode_oms: period.periodDescription,
      cursussen: period.courses
        ?.filter(course => course.ribbonNr <= 1)
        .map(course => this.mapCourse(course)) ?? [],
    } as ApiPeriodeRequest;
  }

  private mapOtherCourses(courses: Course[]) {
    return {
      cursussen: courses?.filter((course) => course.ribbonNr <= 1).map((course) => this.mapCourse(course)) ?? [],
    } as ApiCursussenRequest;
  }

  private mapCourse(course: Course): ApiCursusRequest {
    const result = {
      cursus: course.code,
      korte_naam: course.name,
      eigen_cursus: course.ownCourse,
      eigen_cursus_punten: course.ownCoursePoints ?? null,
      lint_periodes: ApiHelper.numberArrayToString(course.ribbonPeriods),
      plan_later: ApiHelper.booleanToCaciBoolean(course.isInPlanLater),
    } as ApiCursusRequest;

    if (course.id) {
      result.spcu_id = course.id;
    }

    return result;
  }

  private mapProfilering(profile: PlanLaterProfile): ApiProfileringRequest {
    // prettier-ignore
    return {
      minors: profile.minors.map(minor => this.mapMinor(minor)),
      cursussen: profile.courses
        ?.filter(course => course.ribbonNr <= 1)
        .map(course => this.mapCourse(course)) ?? [],
    } as ApiProfileringRequest;
  }

  private mapMinor(minor: MinorProfile): ApiMinorsRequest {
    // prettier-ignore
    return {
      examenonderdelen: minor.examComponents
        .map(examComponent => this.mapMinorExamComponent(examComponent),
      ),
      minor: minor.minor,
      minor_examenprogramma: minor.studyProgram,
    } as ApiMinorsRequest;
  }

  private mapMinorExamComponent(examComponent: ProfileMinorExamComponent) {
    return {
      sple_id: examComponent.id,
      examenonderdeel: examComponent.examComponent,
      minor_examenonderdeel: examComponent.minorExamComponent,
    } as ApiMinorExamenonderdeelRequest;
  }

  private mapSpecialisatieRuimte(specialisations: Specialisations | undefined): ApiSpecialisatieRuimteRequest {
    // prettier-ignore
    return {
      specialisaties: specialisations?.specialisations
        .map(specialisation => this.mapSpecialisation(specialisation)) ?? [],
    } as ApiSpecialisatieRuimteRequest;
  }

  private mapSpecialisation(specialisation: Specialisation): ApiSpecialisatieRequest {
    // prettier-ignore
    return {
      examenonderdelen: specialisation.examComponents
        .map(examComponent => this.mapExamenonderdeel(examComponent),
      ),
      specialisatie: specialisation.code,
      spec_examenprogramma: specialisation.studyProgramCode,
      // tweede_examenprogramma: specialisation.secondStudyProgram, // TODO welk veld?
    } as ApiSpecialisatieRequest;
  }

  private mapExamenonderdeel(examComponent: ExamComponent) {
    return {
      sple_id: examComponent.id,
      examenonderdeel: examComponent.examComponent,
      spec_examenonderdeel: examComponent.examComponent,
    } as ApiExamenonderdeelRequest;
  }
}
