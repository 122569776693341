import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

/**
 * This class is an abstract class that can be implemented to create a store,
 * which allows you to store state and retrieve it.
 */
export abstract class AbstractStore<T> {
  private _state$: BehaviorSubject<T>;

  protected constructor(initialState: T) {
    this._state$ = new BehaviorSubject(initialState);
  }

  public get state(): T {
    return this._state$.getValue();
  }

  public set state(nextState: T) {
    this._state$.next(nextState);
  }

  public select<S>(selectFn: (state: T) => S): Observable<S> {
    return this._state$.pipe(map(selectFn), distinctUntilChanged());
  }
}
