import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, first, map } from "rxjs/operators";
import { CoreStateValue } from "../states/core.state";
import { CoreStore } from "../states/core.store";

export interface AuthenticationResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  scope: string;
  refresh_token: string;
}

/**
 * This service is responsible for managing the access and refresh tokens,
 * and to manage logout if there is something wrong with the tokens.
 */
@Injectable({
  providedIn: "root",
})
export class TokenService {
  private shouldLogoutSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public shouldLogout: Observable<boolean> = this.shouldLogoutSubject.asObservable();

  constructor(
    private http: HttpClient,
    public store: CoreStore,
  ) {}

  getAccessToken(): CoreStateValue {
    return this.store.getAccessToken();
  }

  setAccessToken(token: string): void {
    this.store.setAccessToken(token);
  }

  getTokenType(): CoreStateValue {
    return this.store.getTokenType();
  }

  setTokenType(tokenType: string): void {
    this.store.setTokenType(tokenType);
  }

  getRefreshToken(): CoreStateValue {
    return this.store.getRefreshToken();
  }

  setRefreshToken(token: string): void {
    this.store.setRefreshToken(token);
  }

  removeRefreshToken(): void {
    this.store.removeRefreshToken();
  }

  removeAllTokenData(): void {
    this.store.removeAccessToken();
    this.store.removeRefreshToken();
    this.store.removeTokenType();
  }

  getNewAccessAndRefreshToken(environmentUrl: string): Observable<string | undefined> {
    const tokenUrl = `${environmentUrl}/token`;
    return this.makeRefreshTokenCall(tokenUrl).pipe(
      map((authenticationResponse: AuthenticationResponse) => {
        this.setAccessToken(authenticationResponse.access_token);
        this.setRefreshToken(authenticationResponse.refresh_token);
        return authenticationResponse.access_token;
      }),
      first(),
    );
  }

  private makeRefreshTokenCall(tokenUrl: string): Observable<AuthenticationResponse> {
    const body = { refresh_token: this.getRefreshToken() };
    const options: object = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return this.http.post<AuthenticationResponse>(tokenUrl, body, options).pipe(first(), catchError(this.handleError));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(error: Error): Observable<any> {
    console.error(error);
    return throwError(() => new Error(error.message));
  }

  triggerLogout(): void {
    this.shouldLogoutSubject.next(true);
  }
}
