import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { MainPage } from "@app/shared/types/pages";
import { ApiStatusResponse } from "@core/api/model/api-plan";
import { Constants } from "@core/constants";
import { ApiHelper } from "@core/domain/helpers/api-helper";
import { StatusResponse } from "@core/domain/plan-details";
import { environment } from "@environment/environment";
import { StatusMessageService } from "@feature/plan/services/status-message.service";
import { WINDOW } from "@ng-web-apis/common";
import { TranslateService } from "@ngx-translate/core";
import { NEVER, Observable, catchError, map } from "rxjs";
import { TokenService } from "./token.service";

type GenericHeader = { [name: string]: string };

@Injectable({
  providedIn: "root",
})
export abstract class GenericHttpService {
  private baseUrl: string;
  protected router = inject(Router);
  protected httpClient = inject(HttpClient);
  protected translateService = inject(TranslateService);
  protected window = inject<Window>(WINDOW);
  protected statusMessageService = inject(StatusMessageService);
  protected tokenService = inject(TokenService);

  constructor() {
    this.baseUrl = `${environment.url}`;
  }

  protected httpDelete<T>(
    urlPath: string,
    params: HttpParams = {} as HttpParams,
    headers: GenericHeader = {},
  ): Observable<T> {
    const url = `${this.baseUrl}/${urlPath}`;
    // console.log(`>>> httpDelete: url: ${url}`, params ?? "");
    const options = {
      headers: this.getHeaders(headers),
      params,
    };

    return this.httpClient.delete<T>(url, options).pipe(
      // tap((data) => {
      //   console.log(`httpDelete /${urlPath}, result:`, data);
      // }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  protected httpGet<T>(
    urlPath: string,
    params: HttpParams = {} as HttpParams,
    headers: GenericHeader = {},
    forPage?: MainPage,
  ): Observable<T | undefined> {
    const url = `${this.baseUrl}/${urlPath}`;
    // console.log(`>>> httpGet: url: ${url}`, params ?? "");
    const options = {
      headers: this.getHeaders(headers),
      params,
    };

    return this.httpClient.get<T>(url, options).pipe(
      // tap((response) => console.log(`httpGet /${urlPath}, result:`, response)),
      map((response) => this.handleResponseStatus(response, forPage)),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  private handleResponseStatus<T>(response: T, forPage?: MainPage): T | undefined {
    // Examine response to see if it contains only statusmeldingen (in which case it is in fact a ApiStatusResponse)
    const props = Object.getOwnPropertyNames(response);
    if (props && props.length === 1 && props[0] === "statusmeldingen") {
      const statusResponse = {
        statusMessages: (response as ApiStatusResponse).statusmeldingen.map((msg) => ApiHelper.toStatusMessage(msg)),
      } as StatusResponse;
      this.statusMessageService.setStatusMessages(statusResponse.statusMessages, forPage);

      return undefined;
    }

    return response;
  }

  protected httpPost<T>(urlPath: string, params?: HttpParams, body?: string | File): Observable<T> {
    const url = `${this.baseUrl}/${urlPath}`;
    // console.log(`>>> httpPost: url: ${url}`, params ?? "");
    const options = {
      headers: this.getHeaders(),
      params: params ?? ({} as HttpParams),
    };

    return this.httpClient.post<T>(url, body, options).pipe(
      // tap((data) => {
      //   console.log(`httpPost /${urlPath}, result:`, data);
      // }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  protected httpPut<T>(
    urlPath: string,
    params?: HttpParams,
    body?: string | File,
    headers: GenericHeader = {},
  ): Observable<T> {
    const url = `${this.baseUrl}/${urlPath}`;
    // console.log(`>>> httpPut: url: ${url}`, params ?? "", headers);
    const options = {
      headers: this.getHeaders(headers),
      params: params ?? ({} as HttpParams),
    };

    return this.httpClient.put<T>(url, body, options).pipe(
      // tap((data) => {
      //   console.log(`httpPut /${urlPath}, result:`, data);
      // }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  protected httpGetBlob(urlPath: string, headers: GenericHeader): Observable<Blob> {
    const url = `${this.baseUrl}/${urlPath}`;

    return (
      this.httpClient
        .get(url, {
          headers: this.getHeaders(headers),
          responseType: "blob",
        })
        //
        .pipe(
          //
          catchError((error: HttpErrorResponse) => this.handleError(error)),
        )
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const redirectUrl = this.extractRedirectUrlFromResponse(error);
    if (redirectUrl !== undefined) {
      const isLoginPage = this.window.location.href.includes("/login");
      // Normal flow: user is not yet authenticated, redirect to login page
      if (!isLoginPage) {
        // console.log(">>> Redirecting to URL:", redirectUrl);

        // Redirecting to a page outside this application.
        this.window.location.href = redirectUrl;
      }

      return NEVER;

      // After login the app returns in http://localhost:4200/#access_token=<token>&token_type=bearer&expires_in=0
    }

    throw error;
  }

  protected extractRedirectUrlFromResponse(
    response: HttpErrorResponse | HttpResponse<string> | string | undefined,
  ): string | undefined {
    if (!response) {
      return undefined;
    }

    const paramRedirectUrl = "Authenticate-Redirect-Url";
    if (response instanceof HttpErrorResponse) {
      return response.error[paramRedirectUrl];
    }

    if (response instanceof HttpResponse) {
      const json = response.body || "{}";
      return JSON.parse(json)[paramRedirectUrl];
    }

    if (typeof response === "object") {
      return response[paramRedirectUrl];
    }

    return JSON.parse(response)[paramRedirectUrl];
  }

  private getHeaders(headers: GenericHeader = {}): HttpHeaders {
    const lang = this.translateService.currentLang || Constants.LANGUAGE_NL;
    const token = `${this.tokenService.getTokenType() || ""} ${this.tokenService.getAccessToken() || ""}`;

    return new HttpHeaders({
      authorization: `${token}`,
      "content-type": "application/json",
      taal: lang.toUpperCase(),
      ...headers,
      // TODO - application_type: this.isAanroepUitDocent() ? 'docent' : ''
    });
  }

  protected getStudentNrHeader(studentNr: string): GenericHeader {
    return {
      "OS-STUDENTNUMMER": studentNr,
    };
  }
}
