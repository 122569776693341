import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import { CoreStateValue } from "../states/core.state";
import { TokenService } from "./token.service";

/**
 * Intercepts all HTTP requests and adds the Authorization header with the token.
 * If the token is expired, it will refresh the token and retry the request.
 * If the refresh token is expired, it will redirect to the login page.
 * This is done to allow for refresh token rotation
 * See https://auth0.com/docs/secure/tokens/refresh-tokens/refresh-token-rotation for more information
 * Subsequent requests will use the new token, or wait for the new token if it is still being refreshed.
 * In the event that an access token has expired, we must therefore retrieve a new access token by means of a refresh token.
 * But for extra security you want such a refresh token to be valid only once. For example: if there are 3 calls at the same time,
 * all of them end up in the 401 handler, we have to retrieve a new access and refresh token with 1 of the missed calls.
 * The other calls continue to 'wait' based on this BehaviorSubject until it contains a new valid token
 * and then also participate in their api calls.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  // We explicitly set the value to undefined and wait with making new calls until it is filled with a new token which will then be used by all calls.
  private tokenSubject: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  private addToken(request: HttpRequest<never>, token: CoreStateValue): HttpRequest<never> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  intercept(
    request: HttpRequest<never>,
    httpHandler: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const tokenService = inject(TokenService);
    return httpHandler.handle(this.addToken(request, tokenService.getAccessToken())).pipe(
      catchError(
        (
          error,
        ): Observable<
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>
        > => {
          // handle 401 Unauthorized errors if we have a refresh token
          if (
            error instanceof HttpErrorResponse &&
            (error as HttpErrorResponse).status === 401 &&
            tokenService.getRefreshToken() !== undefined
          ) {
            return this.handle401Error(request, error, httpHandler, tokenService);
          } else {
            return throwError(() => new Error(error.message));
          }
        },
      ),
    );
  }

  /**
   * Handles the 401 error by redirecting, or refreshing the token and retrying the request.
   * A 401 is given if the user is not logged in yet, or the access token has expired and the user is thus unauthorized
   * @returns An observable that will retry the request
   */
  private handle401Error(
    request: HttpRequest<never>,
    errorResponse: HttpErrorResponse,
    httpHandler: HttpHandler,
    tokenService: TokenService,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const baseUrl = request.url.split("?")[0];
    if (baseUrl.includes("authenticeer") || this.errorHasRedirectUrlWithStatusMelding(errorResponse)) {
      // If the request is to the authentication URL or
      // if error body contains redirect url and redirect url contains status_meldingen
      // pass the request along without modification.
      return httpHandler.handle(request);
    } else if (!this.isRefreshingToken) {
      // If the request is not to the authentication URL and a token refresh is not already in progress,
      // start a token refresh.
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(undefined);

      return tokenService.getNewAccessAndRefreshToken(environment.url).pipe(
        switchMap((newToken: string | undefined) => {
          if (this.tokenIsEmpty(newToken)) {
            tokenService.triggerLogout();
            return throwError(() => new Error("Token is empty"));
          }
          this.tokenSubject.next(newToken);
          return httpHandler.handle(this.addToken(request, newToken));
        }),
        catchError((error) => {
          tokenService.triggerLogout();
          return throwError(() => new Error(error.message));
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      // If a token refresh is in progress, wait until a new token is available or the refresh is complete.
      // Then, pass the request along with the new token.
      // Switchmap: Maps values to observable (this is needed in this interceptor chain).
      // Cancels the previous inner observable.
      return this.tokenSubject.pipe(
        filter((token) => !this.tokenIsEmpty(token)),
        take(1),
        switchMap((token) => {
          return httpHandler.handle(this.addToken(request, token));
        }),
      );
    }
  }

  private tokenIsEmpty(token: string | undefined): boolean {
    return token === undefined || token === null || token === "";
  }

  // Checks if the error has body with redirect url and redirect url contains "status_meldingen"
  private errorHasRedirectUrlWithStatusMelding(errorResponse: HttpErrorResponse): boolean {
    if (errorResponse.error?.["Authenticate-Redirect-Url"]) {
      return errorResponse.error["Authenticate-Redirect-Url"].includes("status_meldingen");
    } else {
      return false;
    }
  }
}
