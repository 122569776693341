import { inject, Injectable } from "@angular/core";
import { CourseTest } from "@app/core/domain/course-test";
import { Constants } from "@core/constants";
import { CourseDetail } from "@core/domain/course-detail";
import { Draft } from "@core/domain/draft";
import { PlanHistory } from "@core/domain/plan-history";
import { PlanProfile } from "@core/domain/plan-profile";
import { CourseService } from "@core/services/course.service";
import { ModalController, ModalOptions } from "@ionic/angular";
import { ComponentProps, ComponentRef } from "@ionic/core";
import { TranslateService } from "@ngx-translate/core";
import { ChangeYearModalComponent } from "@shared/modals/change-year-modal/change-year-modal.component";
import { ConfirmModalComponent } from "@shared/modals/confirm-modal/confirm-modal.component";
import { CourseInfoModalComponent } from "@shared/modals/course-info-modal/course-info-modal.component";
import { NewDraftModalComponent } from "@shared/modals/new-draft-modal/new-draft-modal.component";
import { RenameCopyDraftModalComponent } from "@shared/modals/rename-copy-draft-modal/rename-copy-draft-modal.component";
import { ShowHistoryModalComponent } from "@shared/modals/show-history-modal/show-history-modal.component";
import { SubmitPlanModalComponent } from "@shared/modals/submit-plan-modal/submit-plan-modal.component";
import { CourseData } from "@shared/types/course-data";
import { from, Observable, of, switchMap } from "rxjs";
import { TestInfoModalComponent } from "../modals/test-info-modal/test-info-modal.component";

export type ModalInput = {
  component: ComponentRef;
  componentProps?: ComponentProps<ComponentRef>;
  cssClass?: string | string[];
};

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private courseService = inject(CourseService);
  private modalController = inject(ModalController);
  private translate = inject(TranslateService);

  createModal(modalInput: ModalInput): Promise<HTMLIonModalElement> {
    const modalOptions: ModalOptions = {
      component: modalInput.component,
      animated: true,
      componentProps: modalInput.componentProps,
      keyboardClose: true,
      cssClass: modalInput.cssClass,
    };

    return this.modalController.create(modalOptions);
  }

  handleAlertModal(title: string, text: string): Observable<boolean> {
    const modalInput: ModalInput = {
      component: ConfirmModalComponent,
      componentProps: {
        title,
        text,
        textCancel: undefined,
      },
      cssClass: "confirm-modal",
    };

    return from(
      this.createModal(modalInput).then(async (modal) => {
        {
          await modal.present();
          const result = await modal.onWillDismiss();
          return result.role === Constants.BUTTON_ACTION_OK;
        }
      }),
    );
  }

  handleConfirmModal(title: string, text: string, info?: string): Observable<boolean> {
    const modalInput: ModalInput = {
      component: ConfirmModalComponent,
      componentProps: {
        title,
        text,
        info,
      },
      cssClass: "confirm-modal",
    };

    return from(
      this.createModal(modalInput).then(async (modal) => {
        {
          await modal.present();
          const result = await modal.onWillDismiss();
          return result.role === Constants.BUTTON_ACTION_OK;
        }
      }),
    );
  }

  handleChangeYearModal(title: string): Observable<number> {
    const modalInput: ModalInput = {
      component: ChangeYearModalComponent,
      componentProps: {
        title,
      },
      cssClass: "change-year-modal",
    };

    return from(
      this.createModal(modalInput).then(async (modal) => {
        {
          await modal.present();
          const result = await modal.onWillDismiss();
          return result.data;
        }
      }),
    );
  }

  showCourseInfoModal(courseData: CourseData, isScheduleSelectable: boolean, view: string): Observable<void> {
    return this.courseService.fetchCourseForYear(courseData.academicYear, courseData.code, courseData.planId).pipe(
      switchMap((courseDetail) => {
        return courseDetail
          ? this.handleCourseInfoModal(courseData, courseDetail, isScheduleSelectable, view)
          : of(void 0);
      }),
    );
  }

  private handleCourseInfoModal(
    courseData: CourseData,
    courseDetail: CourseDetail,
    isScheduleSelectable: boolean,
    viewStartedBy: string,
  ): Observable<void> {
    const modalInput: ModalInput = {
      component: CourseInfoModalComponent,
      componentProps: {
        courseData,
        courseDetail,
        isScheduleSelectable: isScheduleSelectable,
        viewStartedBy,
      },
      cssClass: "course-info-modal",
    };

    return from(
      this.createModal(modalInput).then(async (modal) => {
        await modal.present();
      }),
    );
  }

  async showTestInfoModal(courseCode: string, courseName: string, courseTests: CourseTest[]): Promise<void> {
    const testInfoModalInput: ModalInput = {
      component: TestInfoModalComponent,
      componentProps: {
        courseCode,
        courseName,
        courseTests,
      },
      cssClass: "test-info-modal",
    };

    const modal = await this.createModal(testInfoModalInput);
    await modal.present();
  }

  handleShowHistoryModal(history: PlanHistory): Observable<void> {
    const modalInput: ModalInput = {
      component: ShowHistoryModalComponent,
      componentProps: {
        title: this.translate.instant("schedule.history.dialogTitle"),
        history,
      },
      cssClass: "show-history-modal",
    };

    return from(
      this.createModal(modalInput).then(async (modal) => {
        await modal.present();
      }),
    );
  }

  handleShowSubmitPlanModal(profile: PlanProfile): Observable<void> {
    const modalInput: ModalInput = {
      component: SubmitPlanModalComponent,
      componentProps: {
        profile: profile,
      },
      cssClass: "submit-plan-modal",
    };

    return from(
      this.createModal(modalInput).then(async (modal) => {
        await modal.present();
      }),
    );
  }

  renameOrCopyDraft(title: string, textOK: string): Observable<string | undefined> {
    const modalInput: ModalInput = {
      component: RenameCopyDraftModalComponent,
      componentProps: {
        title,
        textOK,
      },
      cssClass: "rename-draft-modal",
    };

    return this.startModal<string | undefined>(modalInput);
  }

  handleNewDraftModal(title: string): Observable<Draft | undefined> {
    const modalInput: ModalInput = {
      component: NewDraftModalComponent,
      componentProps: {
        title,
      },
      cssClass: "new-draft-modal",
    };

    return this.startModal<Draft | undefined>(modalInput);
  }

  private startModal<T>(modalInput: ModalInput): Observable<T> {
    return from(
      this.createModal(modalInput).then(async (modal) => {
        {
          await modal.present();
          const result = await modal.onWillDismiss();
          return result.data;
        }
      }),
    );
  }
}
