<ion-header>
  <ion-toolbar color="light">
    <ion-button fill="clear" slot="start">
      <ion-title>
        <b>{{ courseCode }}</b> {{ courseName }}
      </ion-title>
    </ion-button>
    <ion-button fill="clear" (click)="dismiss()" slot="end" [attr.aria-label]="'ariaLabel.closeButton' | translate">
      <ion-icon icon="close" />
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p class="ion-padding-horizontal">
    {{ (courseTests.length === 1 ? "planCourse.testListIntroSingle" : "planCourse.testListIntroMultiple") | translate }}
  </p>
  <ul>
    <div *ngFor="let coursetest of courseTests">
      <li>
        <p>{{ coursetest.testDescription }}</p>
      </li>
    </div>
  </ul>
</ion-content>
