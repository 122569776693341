@if (courseDetail) {
  <div class="pa-plan-element-year-grid">
    <!-- Empty column-->
    <div></div>
    <!-- Headers for periods -->
    @for (period of courseDetail.studyYear[0].periods; track period) {
      <div class="pa-plan-header-period">{{ period.periodDescription }}</div>
    }
    @for (year of courseDetail.studyYear; track year.yearNr) {
      <!--Year description column -->
      <div class="pa-plan-year-description">
        {{ "schedule.labelAcademicYear" | translate }} {{ year.yearNr }} ({{ year.academicYearDescription }})
      </div>
      <!--Columns for period per study year -->
      @for (period of year.periods; track period.periodNr) {
        <div
          class="pa-plan-grid"
          [ngClass]="{ 'pa-plan-grid-hoverable': isScheduleSelectable && period.offered }"
          (click)="clickOnPeriod(year, period)"
        >
          @if (period.offered) {
            <div class="timeslots" tabindex="0">
              @if (period.timeslots.length > 0) {
                @for (timeslot of period.timeslots; track $index) {
                  <pa-timeslot
                    class="timeslot"
                    [timeslot]="timeslot"
                    [attr.aria-label]="
                      year.yearNr + ' ' + year.academicYearDescription + ' ' + period.periodDescription + ' ' + timeslot
                    "
                  />
                }
              } @else {
                <pa-timeslot class="timeslot timeslot-empty" />
              }
            </div>
          }
        </div>
      }
    }
  </div>
}
