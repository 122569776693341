import { booleanAttribute, Component, inject, Input, OnInit } from "@angular/core";
import { Constants } from "@core/constants";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "@shared/services/modal.service";
import { PlanActionService } from "@shared/services/plan-action.service";
import { CourseData } from "@shared/types/course-data";

@Component({
  selector: "pa-course-item",
  templateUrl: "./course-item.component.html",
  styleUrls: ["./course-item.component.scss"],
})
export class CourseItemComponent implements OnInit {
  @Input() courseData!: CourseData;
  @Input({ transform: booleanAttribute }) showActions = false;
  @Input({ transform: booleanAttribute }) showCategory = false;
  @Input({ transform: booleanAttribute }) hideTimeslots = false;
  @Input({ transform: booleanAttribute }) hideShowInfo = false;
  @Input({ transform: booleanAttribute }) isScheduleSelectable = false;
  @Input() showPlanLaterButton = false;
  @Input() viewStartedBy!: string;

  courseHelper = inject(CourseHelper);
  hoverItem = false;
  itemId = `item-${Math.random().toString(36).substring(7)}`;

  protected readonly Constants = Constants;
  private planActionService = inject(PlanActionService);
  private modalService = inject(ModalService);
  private translateService = inject(TranslateService);
  courseObtainedTooltip = "";

  ngOnInit(): void {
    if (this.courseData.statusObtained !== undefined) {
      this.courseObtainedTooltip = this.translateService.instant(
        this.courseHelper.getStatusObtainedLabel(this.courseData),
      );
    }
  }

  onPlanNow(event?: MouseEvent): void {
    event && event.stopPropagation();

    this.planActionService.planNow(this.courseData);
  }

  onPlanLater(event?: MouseEvent): void {
    event && event.stopPropagation();
    this.courseData = this.planActionService.planLater(this.courseData);
  }

  onRemoveCourse(event?: MouseEvent): void {
    event && event.stopPropagation();
    this.planActionService.removeCourseFromPlanLater(this.courseData);
  }

  async onCourseInfo(event?: MouseEvent): Promise<void> {
    event && event.stopPropagation();
    this.modalService.showCourseInfoModal(this.courseData, this.isScheduleSelectable, this.viewStartedBy).subscribe();
  }

  determineCourseSideColor(): string {
    if (this.courseData.courseColor) {
      return this.courseData.courseColor;
    }
    if (this.hoverItem) {
      return "var(--pa-color-action-hover)";
    }

    return this.courseData.yearNr > 0 && this.courseData.periodDescription !== ""
      ? "var(--pa-grid-color-course-obtained)"
      : "white";
  }
}
