import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule, inject, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { Constants } from "@core/constants";
import { CoreModule } from "@core/core.module";
import { GlobalErrorHandler } from "@core/error/global-error-handler";
import { AuthService } from "@core/services/auth.service";
import { ConfigParamsAndScreenTextsService } from "@core/services/config-params-and-screen-texts.service";
import { WINDOW_PROVIDERS } from "@core/services/window.service";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { WINDOW } from "@ng-web-apis/common";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxPermissionsModule } from "ngx-permissions";
import { EMPTY, Observable, map, switchMap } from "rxjs";
import { AuthInterceptor } from "./core/services/auth-interceptor.service";
import { LanguageService } from "./shared/services/language.service";

const translateHttpLoaderFactory = (httpClient: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(httpClient, `./assets/translations/`, ".json");
};

function initializeApp(
  authService: AuthService,
  configParamsAndScreenTextsService: ConfigParamsAndScreenTextsService,
  translateService: TranslateService,
  languageService: LanguageService,
  globalErrorHandler: GlobalErrorHandler,
): () => Observable<void> {
  const window = inject<Window>(WINDOW);

  if (window.location.href.includes("/#/")) {
    window.location.replace(window.location.href.replace("/#/", "/"));
  }
  if (window.location.href.includes("status_meldingen")) {
    return () => {
      globalErrorHandler.handleRejectedDatabaseLogin();
      return EMPTY;
    };
  } else {
    // console.log("Initializing PlanApp..., url=", window.location.href);
    return () => {
      const lang = languageService.determineLanguage();
      return translateService.use(lang).pipe(
        switchMap(() => authService.authorize()),
        switchMap(() => configParamsAndScreenTextsService.loadConfigParamsAndScreenTexts()),
        // tap(() => console.log('PlanApp initialized, Configuration loaded.')),
        map(() => void 0),
      );
    };
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    TranslateModule.forRoot({
      defaultLanguage: Constants.LANGUAGE_NL,
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, ConfigParamsAndScreenTextsService, TranslateService, LanguageService, GlobalErrorHandler],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
