<header class="pa-app-header-toolbar">
  <img class="pa-app-header-logo" [src]="customLogo" alt="Logo" (click)="router.navigate(['/plan'])" />

  <ion-segment [value]="selectedPage" selectOnFocus="true" (ionChange)="onMainTabClicked($event)">
    <ion-segment-button value="plan" routerLink="/plan">
      <ion-label [attr.aria-label]="'label.plan' | translate" role="navigation">
        {{ "label.plan" | translate }}
      </ion-label>
    </ion-segment-button>

    @if (plansAvailable()) {
      <ng-template [ngxPermissionsExcept]="Constants.ROLE_EMPLOYEE_VIEWS_STUDENT_PLAN">
        <ion-segment-button value="catalog" routerLink="/catalog">
          <ion-label [attr.aria-label]="'label.catalog' | translate" role="navigation">
            {{ "label.catalog" | translate }}
          </ion-label>
        </ion-segment-button>
      </ng-template>
    }
    <ion-segment-button value="curriculum" routerLink="/curriculum">
      <ion-label [attr.aria-label]="'label.curriculum' | translate" role="navigation">
        {{ "label.curriculum" | translate }}
      </ion-label>
    </ion-segment-button>
  </ion-segment>

  @if (user) {
    <ion-button id="user-button" fill="clear" color="light" (click)="loginMenuOpen = true" class="login-button">
      <ion-icon icon="chevron-down-outline" slot="end" aria-label="Open logout option"></ion-icon>
      <span class="wrapped-button-text">
        @if (user.studentNr) {
          {{ user.studentNr }} -
        }
        {{ user.name }}
      </span>
    </ion-button>
    <ion-popover trigger="user-button" [isOpen]="loginMenuOpen">
      <ng-template>
        <ion-content class="ion-padding">
          <ion-list>
            <ion-item [button]="true" [detail]="false" (click)="onLogout()">
              {{ "label.logout" | translate }}
            </ion-item>
          </ion-list>
        </ion-content>
      </ng-template>
    </ion-popover>
  } @else {
    <ion-button id="login-button" fill="clear" color="success">
      <ion-icon icon="log-in-outline" slot="end"></ion-icon>
      {{ "label.login" | translate }}
    </ion-button>
  }

  <pa-language-selector />
</header>
