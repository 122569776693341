export const SCREENTEXT_DISCLAIMER = "PLANAPP-DISCLAIMER";
export const SCREENTEXT_MINORS = "MINOREN";
export const SCREENTEXT_SPECIALISATIONS = "SPECIALISATIES";
export const SCREENTEXT_RESUBMIT_VALIDATED_PLAN = "GOEDGEKEURD-VOORSTEL-OPNIEUW-INDIENEN";

export const PARAM_INSTITUTION = "INSTELLING";
export const PARAM_STUDENT_BASE_URL = "STUDENT-APPSRV-URL";

export type ConfigParamOrScreenText = {
  key: string;
  value: string;
};
