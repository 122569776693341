import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { AbstractStore } from "./abstract.store";
import { CoreState, CoreStateValue } from "./core.state";

/**
 * This class is a store that allows you to store state and retrieve it.
 * It hides the technical details of where the state is stored,
 * and provides get/set/remove methods for the specific state variables that can be stored and retrieved.
 * Good to know: the state is cleared with each new browser session.
 */
@Injectable({
  providedIn: "root",
})
export class CoreStore extends AbstractStore<CoreState> {
  private readonly ACCESS_TOKEN = "ACCESS_TOKEN";
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";
  private readonly TOKEN_TYPE = "TOKEN_TYPE";
  private readonly STUDENT_PLAN_ID = "studentPlanId";

  constructor(public platform: Platform) {
    super(new CoreState());
    // read initial values from storage
    this.initValue(this.ACCESS_TOKEN);
    this.initValue(this.REFRESH_TOKEN);
    this.initValue(this.TOKEN_TYPE);
  }

  public setAccessToken(value: CoreStateValue): void {
    this.setValue(this.ACCESS_TOKEN, value);
  }

  public getAccessToken(): CoreStateValue {
    return this.getValue(this.ACCESS_TOKEN);
  }

  public removeAccessToken(): void {
    this.removeValue(this.ACCESS_TOKEN);
  }

  public setRefreshToken(value: string): void {
    this.setValue(this.REFRESH_TOKEN, value);
  }

  public getRefreshToken(): CoreStateValue {
    return this.getValue(this.REFRESH_TOKEN);
  }

  public removeRefreshToken(): void {
    this.removeValue(this.REFRESH_TOKEN);
  }

  public setTokenType(value: CoreStateValue): void {
    this.setValue(this.TOKEN_TYPE, value);
  }

  public getTokenType(): CoreStateValue {
    return this.getValue(this.TOKEN_TYPE);
  }

  public removeTokenType(): void {
    this.removeValue(this.TOKEN_TYPE);
  }

  public setStudentPlanIdForEmployee(value: CoreStateValue): void {
    // The student planId is stored in the local storage,
    // because it must survive a session when linking from Osiris Docent/Begeleider
    // while the employee is not logged into the PlanApp yet
    localStorage.setItem(this.STUDENT_PLAN_ID, value || "");
  }

  public getStudentPlanIdForEmployee(): CoreStateValue {
    // The student planId is stored in the local storage,
    // because it must survive a session when linking from Osiris Docent/Begeleider
    // while the employee is not logged into the PlanApp yet
    return localStorage.getItem(this.STUDENT_PLAN_ID) || undefined;
  }

  public removeStudentPlanIdForEmployee(): void {
    this.removeValue(this.STUDENT_PLAN_ID);
  }

  private initValue(prop: string): void {
    const value = this.getStorage()[prop];
    this.setStateValue(prop, value || "");
  }

  private setValue(prop: string, value: CoreStateValue): void {
    this.setStateValue(prop, value);
    this.getStorage()[prop] = value;
  }

  private getValue(prop: string): CoreStateValue {
    return this.state[prop];
  }

  private removeValue(prop: string): void {
    this.removeProperty(prop);
    delete this.getStorage()[prop];
  }

  private setStateValue(prop: string, value: CoreStateValue): void {
    this.state = {
      ...this.state,
      [prop]: value,
    };
  }
  private removeProperty(propertyName: string) {
    const newState = { ...this.state };
    delete newState[propertyName];
    this.state = newState;
  }

  private getStorage() {
    return window.sessionStorage;
  }
}
