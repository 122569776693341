import { Component, DestroyRef, OnInit, computed, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { StatusMessageService } from "@app/features/plan/services/status-message.service";
import { ModalService } from "@app/shared/services/modal.service";
import { MainPage } from "@app/shared/types/pages";
import { Constants } from "@core/constants";
import { PARAM_INSTITUTION } from "@core/domain/config-param-or-screen-text";
import { User } from "@core/domain/user";
import { AuthService } from "@core/services/auth.service";
import { ConfigParamsAndScreenTextsService } from "@core/services/config-params-and-screen-texts.service";
import { SegmentChangeEventDetail } from "@ionic/angular";
import { IonSegmentCustomEvent } from "@ionic/core";
import { TranslateService } from "@ngx-translate/core";
import { PlanStateService } from "@shared/services/plan-state.service";
import { filter } from "rxjs";

@Component({
  selector: "pa-app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  selectedPage: MainPage = MainPage.PLAN;

  protected readonly Constants = Constants;
  private configParamsAndScreenTextsService = inject(ConfigParamsAndScreenTextsService);

  user: User | undefined;
  loginMenuOpen = false;
  customLogo = "";
  router = inject(Router);
  authService = inject(AuthService);
  planStateService = inject(PlanStateService);
  translateService = inject(TranslateService);
  modalService = inject(ModalService);
  private statusMessageService = inject(StatusMessageService);

  plansAvailable = computed(() => {
    const plans = this.planStateService.plans();
    return plans && plans.length > 0;
  });

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    const institutionParam = this.configParamsAndScreenTextsService.getConfigParamOrScreenText(PARAM_INSTITUTION);
    let url = [""];

    if (institutionParam) {
      const logoPath = `/assets/images/customers/logo-${institutionParam.toLowerCase()}.png`;
      const defaultLogoPath = "/assets/images/customers/logo-osiris.png";

      const img = new Image();
      img.src = logoPath;

      img.onload = () => {
        this.customLogo = logoPath;
      };

      img.onerror = () => {
        this.customLogo = defaultLogoPath;
      };
    }

    this.authService
      .getUser$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.user = user;
      });

    this.router.events
      .pipe(
        filter((event: unknown): event is RouterEvent => event instanceof RouterEvent),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((routerEvent: RouterEvent) => {
        switch (true) {
          case routerEvent instanceof NavigationEnd:
            url = routerEvent.url.split("/").filter((part) => part !== "");
            if (url && url.length > 0) {
              this.selectedPage = url[0] as MainPage;
            }
        }
      });
  }

  private doLogout(): void {
    // Navigate to the logout page, which will trigger the logout.
    this.router.navigate(["/session/logout"]);
  }

  onLogout(): void {
    this.closeLoginMenu();
    if (this.planStateService.isPlanModified()) {
      this.modalService
        .handleConfirmModal("general.confirm.confirmTitle", "general.confirm.confirmQuestion")
        .subscribe((result) => {
          result && this.doLogout();
        });
    } else {
      this.doLogout();
    }
  }

  onMainTabClicked(event: IonSegmentCustomEvent<SegmentChangeEventDetail>): void {
    if (event.detail.value !== this.selectedPage) {
      this.statusMessageService.setMainPage(event.detail.value as MainPage);
    }
  }

  private closeLoginMenu() {
    this.loginMenuOpen = false;
  }
}
