import { Injectable } from "@angular/core";
import {
  ApiConfigParamOrScreenText,
  ApiConfigParamsAndScreenTexts,
} from "@app/core/api/model/api-config-param-or-screen-text";
import { GenericHttpService } from "@core/services/generic-http.service";
import { map, Observable, ReplaySubject } from "rxjs";
import { ConfigParamOrScreenText } from "../domain/config-param-or-screen-text";

@Injectable({
  providedIn: "root",
})
export class ConfigParamsAndScreenTextsService extends GenericHttpService {
  private configParamsAndScreenTexts: ConfigParamOrScreenText[] | undefined;
  private configParamsAndScreenTextsSubject = new ReplaySubject<ConfigParamOrScreenText[] | undefined>(1);

  loadConfigParamsAndScreenTexts(): Observable<ConfigParamOrScreenText[]> {
    // even though the backend call is named configs,
    // it actually returns a combination of configuration parameters and screen texts
    // (the ones that are named as constants in config-param-or-screen-text.ts)
    return this.httpGet<ApiConfigParamsAndScreenTexts>("configs").pipe(
      map((apiConfigParamsAndScreenTexts) => this.handleResponse(apiConfigParamsAndScreenTexts)),
    );
  }

  getConfigParamsAndScreenTexts$(): Observable<ConfigParamOrScreenText[] | undefined> {
    return this.configParamsAndScreenTextsSubject.asObservable();
  }

  getConfigParamOrScreenText(key: string): string {
    if (!this.configParamsAndScreenTexts) {
      throw new Error("Configuration parameters and screen texts not loaded");
    }

    const param = this.configParamsAndScreenTexts.find(
      (configParamOrScreenText) => configParamOrScreenText.key === key,
    );
    if (!param) {
      throw new Error(`Configuration parameter or screen text ${key} not found`);
    }

    return param.value;
  }

  private toConfigParamOrScreenText(apiConfigParamOrScreenText: ApiConfigParamOrScreenText): ConfigParamOrScreenText {
    return {
      key: apiConfigParamOrScreenText.parameter,
      value: apiConfigParamOrScreenText.waarde,
    } as ConfigParamOrScreenText;
  }

  private handleResponse(
    apiConfigParamsAndScreenTexts: ApiConfigParamsAndScreenTexts | undefined,
  ): ConfigParamOrScreenText[] {
    if (apiConfigParamsAndScreenTexts) {
      this.configParamsAndScreenTexts = apiConfigParamsAndScreenTexts.items.map((apiConfigParamOrScreenText) =>
        this.toConfigParamOrScreenText(apiConfigParamOrScreenText),
      );
      // console.log("Configuration params and screen texts loaded", apiConfigParamsAndScreenTexts);
    } else {
      this.configParamsAndScreenTexts = [];
      console.warn("Configuration parameters and screen texts NOT loaded", apiConfigParamsAndScreenTexts);
    }

    this.configParamsAndScreenTextsSubject.next(this.configParamsAndScreenTexts);
    return this.configParamsAndScreenTexts;
  }
}
