export type CoreStateValue = string | undefined;
export class CoreState {
  [key: string]: CoreStateValue;

  public readonly ACCESS_TOKEN: CoreStateValue;
  public readonly REFRESH_TOKEN: CoreStateValue;
  public readonly TOKEN_TYPE: CoreStateValue;

  constructor() {
    // set initial state
    this.ACCESS_TOKEN = undefined;
    this.REFRESH_TOKEN = undefined;
    this.TOKEN_TYPE = undefined;
  }
}
