import { Component, Input } from "@angular/core";
import { CourseTest } from "@app/core/domain/course-test";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "pa-test-info-modal",
  templateUrl: "./test-info-modal.component.html",
  styleUrls: ["./test-info-modal.component.scss"],
})
export class TestInfoModalComponent {
  @Input() courseCode!: string;
  @Input() courseName!: string;
  @Input() courseTests!: CourseTest[];

  constructor(private modalCtrl: ModalController) {}

  dismiss(): void {
    this.modalCtrl.dismiss();
  }
}
