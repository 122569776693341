import { Injectable } from "@angular/core";
import { Constants } from "@core/constants";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  getLanguage(): string {
    return sessionStorage.getItem(Constants.LANGUAGE_KEY) ?? Constants.LANGUAGE_DEFAULT;
  }

  setLanguage(language: string): void {
    sessionStorage.setItem(Constants.LANGUAGE_KEY, language);
  }

  /**
   * Get language from a query parameter or local storage, or use the default language
   */
  determineLanguage(): string {
    const translationsAvailable = [Constants.LANGUAGE_NL, Constants.LANGUAGE_EN];
    const urlParams = new URLSearchParams(window.location.search);
    const languageFromQueryParameter = urlParams.get(Constants.LANGUAGE_QUERY_PARAMETER)?.toLowerCase() ?? "";

    if (translationsAvailable.includes(languageFromQueryParameter)) {
      this.setLanguage(languageFromQueryParameter);
    }
    return this.getLanguage();
  }
}
