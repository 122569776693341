// eslint-disable-next-line no-shadow
export enum ProposalStatus {
  SUBMITTED_FOR_APPROVAL = "I", // ingediend
  REJECTED = "A", // afgekeurd
  POSITIVE_ADVICE = "P", // positief advies
  APPROVED = "G", // goedgekeurd
  RESUBMITTABLE = "O", // opnieuw in te dienen
  // never in PlanApp: "B" which means that from Osiris Docent/Begeleider they want to "save" the proposal (bewaren)
}

// eslint-disable-next-line no-shadow
export enum ProposalReviewMoment {
  INTERIM = "T", // tussentijdse beoordeling
  FINAL = "E", // eindbeoordeling
}
