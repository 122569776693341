import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from "@core/constants";
import { Course } from "@core/domain/course";
import { ExamComponent, MinorExamComponent } from "@core/domain/exam-component";
import { CourseHelper } from "@core/domain/helpers/course-helper";
import { PlanHelper } from "@core/domain/helpers/plan-helper";
import { PlanDetails } from "@core/domain/plan-details";
import { Specialisation } from "@core/domain/plan-later";
import { MinorProfile } from "@core/domain/plan-profile";
import { TranslateService } from "@ngx-translate/core";
import { CourseDataMapper } from "@shared/services/course-data-mapping.service";
import { PlanLaterStateService } from "@shared/services/plan-later-state.service";
import { CourseDataAction, CourseDataGroup, CourseDataList } from "@shared/types/course-data";
import { PlanLaterType } from "@shared/types/plan-later-types";

export class PlanLaterCourseGroupBuilder {
  private router = inject(Router);
  private translate = inject(TranslateService);
  private courseHelper = inject(CourseHelper);
  private planHelper = inject(PlanHelper);
  private courseDataMapper = inject(CourseDataMapper);

  buildExamComponentCourseGroup(
    examComponent: ExamComponent,
    type: PlanLaterType,
    selectedPlan: PlanDetails | undefined,
  ): CourseDataGroup {
    const title = `${examComponent.examComponentName} ${examComponent.label ? ` - ${examComponent.label}` : ""}`;
    const subTitle = examComponent.examComponentExplanation;

    let header: string = "";
    if (type === PlanLaterType.MANDATORY) {
      header = this.translate.instant("examPart.examTypeMandatory");
    }
    if (type === PlanLaterType.MANDATORY_CHOICE && !examComponent.conditionsMet) {
      header = this.translate.instant("examPart.examTypeMandatoryChoice", {
        minimumPoints: examComponent.minimumPoints,
      });
    }
    const courseList: CourseDataList = this.buildCourseList(header, examComponent.courses, type, selectedPlan);

    return {
      id: title,
      title,
      subTitle,
      coursesLinkExamComponentName: this.getCoursesLinkExamComponentName(examComponent),
      courseList: [courseList],
    } as CourseDataGroup;
  }

  buildSpecialisationCourseGroup(
    specialisation: Specialisation,
    examComponents: ExamComponent[],
    selectedPlan: PlanDetails | undefined,
    planLaterStateService: PlanLaterStateService,
  ): CourseDataGroup {
    const title = `${specialisation.code} - ${specialisation.name}`;
    const isPlanned = this.planHelper.isSpecialisationPlanned(specialisation);
    const subTitle = isPlanned ? "planLater.planned" : "planLater.registered";
    const subTitleClass = isPlanned ? "status-not-registered" : "status-registered";

    // prettier-ignore
    const courseList = examComponents
      .map(examComponent => {
        const examCompType = this.planHelper.getMinorExamType(examComponent.type, examComponent.minimumPoints);
        const header = `${examComponent.examComponentName}${examCompType ? ` - ${examCompType}` : ''}`;
        return this.buildCourseList(header, examComponent.courses, PlanLaterType.SPECIALISATION, selectedPlan);
      });

    const courseGroup = {
      id: this.planHelper.stripSpaces(specialisation.name),
      title,
      subTitle: this.translate.instant(subTitle),
      subTitleClass,
      courseList: courseList,
    } as CourseDataGroup;

    if (isPlanned) {
      courseGroup.actions = [
        {
          title: this.translate.instant("label.remove"),
          icon: "trash",
          onAction: (): void => {
            setTimeout(() => {
              // NOTE: without this timeout the menu will not close. Not sure why.
              planLaterStateService.removeSpecialisation(specialisation);
            }, 0);
          },
        } as CourseDataAction,
      ];
    }

    return courseGroup;
  }

  buildMinorCourseGroup(
    minor: MinorProfile,
    examComponents: MinorExamComponent[],
    selectedPlan: PlanDetails | undefined,
    planLaterStateService: PlanLaterStateService,
  ): CourseDataGroup {
    // TODO old code show that sometimes minor.inhoud is shown as "more details" (with or without ellipsis).
    // See MinorComponent.html (getInhoud())
    const title = minor.name;
    const subTitle = minor.statusRegistered ? this.translate.instant("minor.statusRegistered") : undefined;
    const subTitleClass = minor.statusRegistered ? "status-registered" : undefined;

    // For each examComponent build a courseList
    // prettier-ignore
    const planLaterCourseLists = examComponents
      .map(examComponent => {
        const header = this.constructMinorExamComponentHeader(examComponent);
        return this.buildCourseList(header, examComponent.courses, PlanLaterType.PROFILE_MINOR, selectedPlan);
      });

    return {
      id: this.planHelper.stripSpaces(minor.name),
      title,
      subTitle,
      subTitleClass,
      courseList: planLaterCourseLists,
      actions: [
        {
          title: this.translate.instant("courseControl.showMinorInfo"),
          icon: "information-circle",
          onAction: () => {
            this.onMinorShowInfo(minor);
          },
        },
        {
          title: this.translate.instant("label.remove"),
          icon: "trash",
          onAction: () => {
            setTimeout(() => {
              // NOTE: without this timeout the menu will not close. Not sure why.
              planLaterStateService.removeMinor(minor);
            }, 0);
          },
        },
      ],
    } as CourseDataGroup;
  }

  buildCoursesGroup(
    title: string,
    subTitle: string | undefined,
    header: string | undefined,
    courses: Course[],
    type: PlanLaterType,
    selectedPlan: PlanDetails | undefined,
  ): CourseDataGroup {
    return {
      id: title,
      title: title ? this.translate.instant(title) : "",
      subTitle,
      courseList: [this.buildCourseList(header, courses, type, selectedPlan)],
    } as CourseDataGroup;
  }

  private buildCourseList(
    header: string | undefined,
    courses: Course[],
    type: PlanLaterType,
    selectedPlan: PlanDetails | undefined,
  ): CourseDataList {
    const isRemovable =
      type === PlanLaterType.PROFILE_COURSES ||
      type === PlanLaterType.OTHER_COURSES ||
      type === PlanLaterType.REPLACEMENT_COURSES;
    // prettier-ignore
    const courseDataList = courses
      .map(course => this.courseHelper.findPlannedCourse(course, selectedPlan) ?? course)
      .map(course => this.courseDataMapper.mapCourseData(course, selectedPlan, true, isRemovable, type));

    return {
      header,
      courseData: courseDataList,
    } as CourseDataList;
  }

  private constructMinorExamComponentHeader(examComponent: MinorExamComponent): string {
    let header: string = "";

    if (examComponent.type?.length > 0) {
      const examCompType = this.planHelper.getMinorExamType(examComponent.type, examComponent.minimumPoints);
      if (examComponent.minorExamComponentName) {
        header = `${examComponent.minorExamComponentName} - ${examCompType}`;
      }

      if (examComponent.label) {
        header = `${header} - ${examComponent.label}`;
      }
    }

    return header;
  }

  private onMinorShowInfo(minor: MinorProfile): void {
    const minorStudyProgram = this.planHelper.stripSpaces(minor.studyProgram);
    const minorMinor = this.planHelper.stripSpaces(minor.minor);
    this.router.navigate(["/catalog/minors"], {
      queryParams: {
        minor: minorMinor,
        minorCode: minorMinor,
        minorName: minor.name,
        minorDescription: `${minorStudyProgram} ${minor.name}`,
      },
    });
  }

  private getCoursesLinkExamComponentName(examComponent: ExamComponent): string | undefined {
    return examComponent.typeMandatoryChoice === Constants.EXAM_COMPONENT_TYPE_MANDATORY_CHOICE_CONDITION
      ? examComponent.examComponentName
      : undefined;
  }
}
