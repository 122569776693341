import { Component, inject, OnInit } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { User } from "@core/domain/user";
import { AuthService } from "@core/services/auth.service";
import { environment } from "@environment/environment";
import { LoadingService } from "@shared/services/loading-service";
import { filter } from "rxjs";
import { VersionService } from "./core/services/version.service";

@Component({
  selector: "pa-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  user: User | undefined;

  private router = inject(Router);
  private loadingService = inject(LoadingService);
  private versionService = inject(VersionService);

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (environment.name !== "PROD") console.log(`Running env '${environment.name}'`);

    this.router.events
      .pipe(filter((event: unknown): event is RouterEvent => event instanceof RouterEvent))
      .subscribe((routerEvent: RouterEvent) => {
        switch (true) {
          case routerEvent instanceof NavigationStart: {
            this.loadingService.present();
            break;
          }

          case routerEvent instanceof NavigationEnd:
          case routerEvent instanceof NavigationCancel:
          case routerEvent instanceof NavigationError: {
            this.loadingService.dismiss();
            break;
          }

          default: {
            break;
          }
        }
      });

    this.authService.getUser$().subscribe((user) => {
      this.user = user;
    });

    this.versionService.setOsirisVersion();
  }
}
